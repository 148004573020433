import {
  baseUrl,
  get,
  post,
  put,
  destroy,
  toggle,
  isValidCro,
  getDownload
} from './defaults';

export const getCompanyTerminals = (companyId, params) => {
  let queryString = new URLSearchParams(params).toString();
  return get(`${baseUrl}/companies/${companyId}/terminals?${queryString}`);
};

export const getCroStatus = (params = '') => {
  let queryString = new URLSearchParams(params).toString();
  return isValidCro(`/api/cro/isvalid?${queryString}`);
};

export const getCompanyTerminal = (companyId, terminalId) => {
  return get(`${baseUrl}/companies/${companyId}/terminals/${terminalId}`);
};

export const saveTerminal = (companyId, terminal) => {
  return post(`${baseUrl}/companies/${companyId}/terminals`, terminal);
};

export const editTerminal = (companyId, terminalId, terminal) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}`,
    terminal
  );
};

export const deleteTerminal = (companyId, terminalId) => {
  return destroy(`${baseUrl}/companies/${companyId}/terminals/${terminalId}`);
};

export const activeTerminalCount = () => {
  return get(`${baseUrl}/companies/terminals/count`);
};

export const getTerminalDashboardGraphData = (
  companyId,
  terminalId,
  params = ''
) => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/getTerminalDashboardGraphData?${queryParams}`
  );
};

export const getShippingLineDashboardGraphData = (
  companyId,
  terminalId,
  params = {}
) => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/depot-graph-data?${queryParams}`
  );
};

export const getTerminalDashboardActiveCROData = (
  companyId,
  terminalId,
  params = ''
) => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/getActiveCROData?${queryParams}`
  );
};

export const getTerminalDashboardInOutDetail = (
  companyId,
  terminalId,
  params = ''
) => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/getTerminalDashboardInOutDetail?${queryParams}`
  );
};

export const getTerminalDashboardActiveCRODetail = (
  companyId,
  terminalId,
  params = ''
) => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/getCROTypeSizeDetail?${queryParams}`
  );
};

export const getTerminalDashboardStats = (
  companyId,
  terminalId,
  params = ''
) => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/getTerminalDashboardStats?${queryParams}`
  );
};

export const toggleTerminalStatus = (companyId, terminalId) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/toggle-status`
  );
};

export const getTerminalFirm = (companyId, terminalId, firmType, options) => {
  if (!options) {
    options = {
      limit: 'all'
    };
  }
  options.type = firmType;
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms?${pageOptions}`
  );
};

export const getTerminalFirmByAttributes = (
  companyId,
  terminalId,
  firmType,
  options
) => {
  options.type = firmType;
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/firms-by-attributes?${pageOptions}`
  );
};

export const getTerminalFirmByAttributesSearch = (
  companyId,
  terminalId,
  options
) => {
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/search/by-attributes?${pageOptions}`
  );
};

export const getTerminalFirmById = (companyId, terminalId, options) => {
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/search/by-attributes-id/?${pageOptions}`
  );
};

export const getTerminalShippingLines = (
  companyId,
  terminalId,
  options = {}
) => {
  if (!options) {
    options = {
      limit: 'all'
    };
  }
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/shipping-lines?${pageOptions}`
  );
};

export const getShippingLinesByAttributes = (
  companyId,
  terminalId,
  options = {}
) => {
  if (!options) {
    options = {
      limit: 'all'
    };
  }
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/shipping-lines/by-attributes?${pageOptions}`
  );
};

export const getTerminalCurrency = (companyId, terminalId, currencyId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/currencies/${currencyId}`
  );
};

export const getFirm = (companyId, terminalId, firmId, params = '') => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${firmId}?${qs}`
  );
};

export const terminalFirmCount = companyId => {
  return get(`${baseUrl}/companies/${companyId}/terminals/firms/count`);
};

export const getSummaryReport = (companyId, terminalId, firmId, params) => {
  params.type = 'Shipping Line';
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${firmId}/summary/pdf?${qs}`
  );
};

export const getOnGroundReport = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/on-ground-report?${qs}`
  );
};

export const terminalContainerCount = companyId => {
  return get(`${baseUrl}/companies/${companyId}/terminals/containers/count`);
};

export const terminalStaffCount = companyId => {
  return get(`${baseUrl}/companies/${companyId}/terminals/staff/count`);
};

export const getAllRoles = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles`
  );
};

export const getRole = (companyId, terminalId, id) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles/${id}`
  );
};

export const getAllPermissions = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/permissions`
  );
};

export const getPermissionsAgainstRoles = (companyId, terminalId, id = '') => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles/role-permissions/${id}`
  );
};

export const updatePermissionsAgainstRoles = (
  companyId,
  terminalId,
  roleId,
  permissionsAgainstRoles
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles/role-permissions/${roleId}`,
    permissionsAgainstRoles
  );
};

export const addRole = (companyId, terminalId, role) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles`,
    role
  );
};

export const editRole = (companyId, terminalId, roleId, role) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles/${roleId}`,
    role
  );
};

export const deleteRole = (companyId, terminalId, roleId) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles/${roleId}`
  );
};

export const toggleRoleStatus = (companyId, terminalId, roleId) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/roles/${roleId}/toggle-status`
  );
};

export const getAllUsers = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users?${qs}`
  );
};

export const getUser = (companyId, terminalId, userId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users/${userId}`
  );
};

export const addUser = (companyId, terminalId, user) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users`,
    user
  );
};

export const editUser = (companyId, terminalId, userId, user) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users/${userId}`,
    user
  );
};

export const deleteUser = (companyId, terminalId, userId) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users/${userId}`
  );
};

export const toggleUserStatus = (companyId, terminalId, userId) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users/${userId}/toggle-status`
  );
};

export const getUserRoles = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users/roles/all`
  );
};

export const updateUserRoles = (companyId, terminalId, userRoles) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/users/roles/update`,
    userRoles
  );
};

export const getLoginTokens = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/staff_management/login-tokens?${qs}`
  );
};

export const getContainerTransitionServices = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transition-services/eirin-transition-services?${qs}`
  );
};

export const getReviewStatementData = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transition-services/review-data?${qs}`
  );
};

export const getInvoiceItems = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transition-services/invoice-items?${qs}`
  );
};

export const getAllServiceTypes = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/types`
  );
};

export const getServices = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/?${qs}`
  );
};

export const getServicesByAttributes = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/by-attributes/?${qs}`
  );
};

export const getServicesByAttributesTSCharges = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/by-attributes-ts-charges/?${qs}`
  );
};

export const getNormalServices = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/normal-services/?${qs}`
  );
};

export const getAllServicesByAttributesTSCharges = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/all/by-attributes/?${qs}`
  );
};

export const getImageCategories = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/image-categories?${qs}`
  );
};

export const addService = (companyId, terminalId, service) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services`,
    service
  );
};

export const addImageCategory = (companyId, terminalId, service) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/image-categories/add`,
    service
  );
};

export const editService = (companyId, terminalId, serviceId, service) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/${serviceId}`,
    service
  );
};

export const saveService = (companyId, terminalId, service, serviceId = '') => {
  const type = (serviceId && put) || post;
  return type(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/${serviceId}`,
    service
  );
};

export const editImageCategory = (
  companyId,
  terminalId,
  imageId,
  imgCategory
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/image-categories/${imageId}`,
    imgCategory
  );
};

export const toggleImageCategoryStatus = (
  companyId,
  terminalId,
  imageCategoryId
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/image-categories/${imageCategoryId}/toggle-status`
  );
};

export const toggleServiceStatus = (companyId, terminalId, serviceId) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/${serviceId}/toggle-status`
  );
};

export const addServiceCharges = (companyId, terminalId, firmId, charges) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${firmId}/services/default/charges`,
    charges
  );
};

export const getAllVessels = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels?${qs}`
  );
};

export const getVesselsByAttributes = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels/by-attributes?${qs}`
  );
};

export const getVesselsByAttributesSearch = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels/search/by-attributes?${qs}`
  );
};

export const getVesselsById = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels/by-attributes-id?${qs}`
  );
};

export const addVessel = (companyId, terminalId, vessel) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels`,
    vessel
  );
};

export const getAllVoyages = (companyId, terminalId, vesselId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels/${vesselId}/voyages?${qs}`
  );
};

export const getVoyagesByAttributes = (
  companyId,
  terminalId,
  vesselId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels/${vesselId}/voyages/by-attributes?${qs}`
  );
};

export const addVoyage = (companyId, terminalId, vesselId, voyage) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vessels/${vesselId}/voyages`,
    voyage
  );
};

export const searchCROs = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/release/search?${qs}`
  );
};

export const getCRODetails = (companyId, terminalId, cRO_id) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${cRO_id}/details`
  );
};

export const getEIRInForContainer = (companyId, terminalId, containerId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/transition/container/${containerId}`
  );
};

export const getAllEIROuts = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out?${qs}`
  );
};

export const getAllEIROutIds = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/ids?${qs}`
  );
};

export const getEIROutSealNumbers = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/seal-numbers?${qs}`
  );
};

export const addPickupSlip = (companyId, terminalId, pickupSlip) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/pickup-slip`,
    pickupSlip
  );
};

export const isRevertable = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/isRevertable?${qs}`
  );
};
export const getAllActivePickupSlip = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/pickup-slip?${qs}`
  );
};

export const getPickupSlip = (companyId, terminalId, pickupSlipId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/pickup-slip/edit/${pickupSlipId}`
  );
};

export const getAllPickupSlipsAgainstEIROut = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/pickup-slip/eir-outs?${qs}`
  );
};

export const cancelPickupSlip = (companyId, terminalId, pickupSlipId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/pickup-slip/${pickupSlipId}/cancel`,
    data
  );
};

export const addEIROut = (companyId, terminalId, eirout) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out`,
    eirout
  );
};

export const updateEirOutCount = (companyId, terminalId, eiroutId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/${eiroutId}/count`
  );
};

export const revertEIROut = (companyId, terminalId, eiroutId, eirout) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/${eiroutId}/revert`,
    eirout
  );
};

export const getEIROutCount = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eirout/count`
  );
};

export const getEIROutIds = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/get-eir-out-Ids?${qs}`
  );
};

export const getPickupSlipIds = (companyId, terminalId, params = '') => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/pickup-slip/get-pickupSlip-Ids?${qs}`
  );
};

export const getCROsEIROutCount = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/count/all`
  );
};

export const getActiveCROsCount = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/active/count`
  );
};

export const getHistoryReport = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/history-report?${qs}`
  );
};

export const getContainerHistoryReport = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/container-history-report?${qs}`
  );
};

export const getShippingLineContainers = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/eir/in/candidates/?${qs}`
  );
};

export const getGatedInContainers = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/eir/in/gatedIn/?${qs}`
  );
};

export const getCROCandidateContainers = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/eir/in/ready/?${qs}`
  );
};

export const addEir = (companyId, terminalId, eir) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions`,
    eir
  );
};

export const addContainerImages = (companyId, terminalId, uploadedFiles) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionImages`,
    uploadedFiles
  );
};

export const addContainerServiceImages = (
  companyId,
  terminalId,
  uploadedFiles
) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionServiceImages`,
    uploadedFiles
  );
};

export const getContainerTransitionDetail = (
  companyId,
  terminalId,
  id = null
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/${id}/transition-detail`
  );
};

export const updateTransitionServiceCharges = (
  companyId,
  terminalId,
  params
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transition-services/update-charges`,
    params
  );
};

export const updateContainerShippingLine = (
  companyId,
  terminalId,
  containerId,
  params
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/${containerId}/shipping-line/update`,
    params
  );
};

export const editContainerImages = (companyId, terminalId, params) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionImages`,
    params
  );
};

export const deleteTransitionImages = (companyId, terminalId, params) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionImages/delete`,
    params
  );
};

export const deleteTransitionServiceImage = (companyId, terminalId, params) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionServiceImages/delete`,
    params
  );
};

export const enableForShippingLine = (companyId, terminalId, params) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionImages/enable`,
    params
  );
};

export const disableForShippingLine = (companyId, terminalId, params) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionImages/disable`,
    params
  );
};

export const approveSaleUnit = (companyId, terminalId, transitionId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${transitionId}/approveSaleUnit`,
    { transitionId }
  );
};

export const approveSaleUnits = (companyId, terminalId, transitionIds) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/approveSaleUnits`,
    { transitionIds }
  );
};

export const editEir = (companyId, terminalId, eirId, eir) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${eirId}`,
    eir
  );
};

export const changeTransitionStatus = (companyId, terminalId, eirId, eir) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${eirId}/update-status`,
    eir
  );
};

export const addDamageRemarks = (companyId, terminalId, eirId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${eirId}/addDamageRemarks`,
    data
  );
};

export const updateEirGrade = (companyId, terminalId, eirId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${eirId}/change-eir-grade`,
    data
  );
};

export const updateServiceGrade = (companyId, terminalId, eirId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${eirId}/change-service-grade`,
    data
  );
};

export const getServicesInShippingLine = (
  companyId,
  terminalId,
  shippingLineId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${shippingLineId}/services/default/charges`
  );
};

export const getAllEir = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions?${qs}`
  );
};

export const getUnAllocatedTanks = (companyId, terminalId, params) => {
  let queryString = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/unallocated-tanks?${queryString}`
  );
};

export const updateBulkUnAllocatedTanks = (companyId, terminalId, params) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/bulk-update-unallocated-tanks`,
    params
  );
};

export const markUnAllocatedTanks = (
  companyId,
  terminalId,
  transitionId,
  checked
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${transitionId}/mark-unallocated-tanks`,
    { checked }
  );
};

export const getEir = (companyId, terminalId, eirId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${eirId}`
  );
};

export const getBLNumbers = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/blNumbers?${qs}`
  );
};

export const revertEIRIn = (companyId, terminalId, transitionId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${transitionId}/revert-transition`
  );
};

export const revertScrapEIRIn = (companyId, terminalId, transitionId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${transitionId}/revert-scrap-transition`
  );
};

export const markScrapEIRIn = (companyId, terminalId, transitionId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${transitionId}/mark-scrap`
  );
};

export const markEirInFlmTrip = (companyId, terminalId, params = {}) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${params.containerTransitionId}/mark-flm-trip`,
    params
  );
};

export const getEIRInIds = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/getTransitionIds?${qs}`
  );
};

export const getEirInDetail = (
  companyId,
  terminalId,
  containerTransitionId,
  params
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${containerTransitionId}/eir-in-details?${qs}`
  );
};

export const getTransitionDamageHistory = (
  companyId,
  terminalId,
  containerTransitionId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${containerTransitionId}/damage-history`
  );
};

export const getAllTransitionStatuses = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/statuses`
  );
};

export const getGatedInReportData = (companyId, terminalId, filters) => {
  let queryString = new URLSearchParams(filters).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/report?${queryString}`
  );
};


export const getEirReportData = (companyId, terminalId, filters, bulkData = []) => {
  let queryString = new URLSearchParams(filters).toString();
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/report?${queryString}`,
    bulkData
  );
};

export const getGatedOutReportData = (companyId, terminalId, filters) => {
  let queryString = new URLSearchParams(filters).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/report?${queryString}`
  );
};

export const getCustomerFasahReportData = (companyId, terminalId, filters) => {
  let queryString = new URLSearchParams(filters).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/report/customer?${queryString}`
  );
};

export const getEirOutDetail = (
  companyId,
  terminalId,
  containerTransitionId,
  params = ''
) => {
  const queryString = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${containerTransitionId}/eir/out?${queryString}`
  );
};

export const getActiveContainers = (companyId, terminalId, filters) => {
  let queryString = new URLSearchParams(filters).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/active?${queryString}`
  );
};

export const getContainerTransitions = (companyId, terminalId, filters) => {
  let queryString = new URLSearchParams(filters).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/container-transitions?${queryString}`
  );
};

export const getUserDashboardTilesData = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/graph?${qs}`
  );
};

export const getEIRInDayWiseCount = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/day-wise-count?${qs}`
  );
};

export const getEIROutDayWiseCount = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/out/day-wise-count?${qs}`
  );
};

export const getAllEIRIns = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/all?${qs}`
  );
};

export const getTransitionImages = (companyId, terminalId, options = {}) => {
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionImages?${pageOptions}`
  );
};

export const getTransitionServiceImages = (
  companyId,
  terminalId,
  options = {}
) => {
  const pageOptions = new URLSearchParams(options).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionServiceImages?${pageOptions}`
  );
};

export const getContainerImages = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/ContainerTransitionImages/container-images?${qs}`
  );
};

export const getEIRInIdsForFilters = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/eir_ids`
  );
};

export const serviceChargesAgainstShippingLines = (
  companyId,
  terminalId,
  serviceId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/${serviceId}`
  );
};

export const getAllStatusesContainer = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/statuses`
  );
};

export const updateEIRIn = (companyId, terminalId, eirId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/${eirId}`,
    data
  );
};

export const updateServiceAndEIRStatus = (companyId, terminalId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/updateServiceAndEIRStatus`,
    data
  );
};

export const updateServiceAndTransitionStatus = (
  companyId,
  terminalId,
  transitionId,
  data
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${transitionId}/updateServiceAndTransitionStatus`,
    data
  );
};

export const bulkAddGradeAndEirStatus = (companyId, terminalId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/bulkAddGradeAndEirStatus`,
    data
  );
};

export const updateEIRInStatus = (companyId, terminalId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/eir/in/ready/all`,
    data
  );
};

export const markReadyTransitions = (companyId, terminalId, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/mark-ready-transitions`,
    data
  );
};

export const getServicesStatus = (companyId, terminalId, eirInId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/requested/services/eir/${eirInId}`
  );
};

export const updateServiceStatus = (
  companyId,
  terminalId,
  eirInId,
  services,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/requested/services/eir/${eirInId}?${qs}`,
    services
  );
};

export const changeServiceStatus = (
  companyId,
  terminalId,
  changeStatusData
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management`,
    changeStatusData
  );
};

export const changeTransitionServiceStatus = (
  companyId,
  terminalId,
  changeStatusData
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/changeServiceStatus`,
    changeStatusData
  );
};

export const updateTransitionServices = (
  companyId,
  terminalId,
  changeStatusData,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/eirIns/update?${qs}`,
    changeStatusData
  );
};

export const updateTransitionServiceStatus = (
  companyId,
  terminalId,
  changeStatusData
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/eirIns/updateTransitionServiceStatus`,
    changeStatusData
  );
};

export const updateAdditionalTransitionServices = (
  companyId,
  terminalId,
  transitionServicesData
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/additional/update`,
    transitionServicesData
  );
};

export const approveTransitionServices = (
  companyId,
  terminalId,
  transitionServicesData
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/eirIns/approveTransitionServices`,
    transitionServicesData
  );
};

export const rejectTransitionServices = (
  companyId,
  terminalId,
  transitionServicesData
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/eirIns/rejectTransitionServices`,
    transitionServicesData
  );
};

export const checkPendingTransitionServices = (
  companyId,
  terminalId,
  transitionServicesData
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management/eirIns/checkPendingTransitionServices`,
    transitionServicesData
  );
};

export const getServicesAgainstContainer = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/services/management?${qs}`
  );
};

export const addDamageServiceAgainstContainer = (
  companyId,
  terminalId,
  params = ''
) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${params.containerTransitionId}/services`,
    params
  );
};

export const editDamageServiceAgainstContainer = (
  companyId,
  terminalId,
  params = ''
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${params.containerTransitionId}/services`,
    params
  );
};

export const deleteDamageServiceAgainstContainer = (
  companyId,
  terminalId,
  containerArrivalId,
  containerTransitionId,
  serviceID
) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/container_arrival_info/${containerArrivalId}/containers/transitions/${containerTransitionId}/services/${serviceID}`
  );
};

export const getContainerSizes = () => {
  return get(`${baseUrl}/containers/sizes`);
};

export const getContainerSize = containerSizeId => {
  return get(`${baseUrl}/containers/sizes/${containerSizeId}`);
};
//============================ Container Types Services ===================================================

export const getContainerTypes = (params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/container_type?${qs}`);
};

export const getContainerType = containerTypeId => {
  return get(`${baseUrl}/container_type/${containerTypeId}`);
};

export const addContainerType = containerType => {
  return post(`${baseUrl}/container_type`, containerType);
};

export const editContainerType = (containerTypeId, containerType) => {
  return put(`${baseUrl}/container_type/${containerTypeId}`, containerType);
};

export const deleteContainerType = containerTypeId => {
  return destroy(`${baseUrl}/container_type/${containerTypeId}`);
};

//============================ Container Services ===============================================================

export const addContainer = (companyId, terminalId, container) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers`,
    container
  );
};

export const addEdifactContainers = (companyId, terminalId, container) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/edifact`,
    container
  );
};

export const getAllContainers = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers?${qs}`
  );
};

export const getContainerTransitionsHistory = (companyId, terminalId, data) => {
  const qs = new URLSearchParams(data).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/get-container-transitions?${qs}`
  );
};

export const getContainersByAttributes = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/by-attributes?${qs}`
  );
};

export const editBulkContainer = (companyId, terminalId, container) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/bulk_update`,
    container
  );
};

export const bulkBlockUnblockContainers = (companyId, terminalId, params) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/bulk-block-unblock`,
    params
  );
};

export const editContainer = (
  companyId,
  terminalId,
  containerId,
  container
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/${containerId}`,
    container
  );
};

export const getContainer = (companyId, terminalId, containerId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/${containerId}`
  );
};

export const getContainerWithTransition = (companyId, terminalId, containerId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/${containerId}/with-transition`
  );
};

export const toggleContainerStatus = (companyId, terminalId, containerId) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/${containerId}/toggle-status`
  );
};

export const getContainerLogs = (companyId, terminalId, containerId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/${containerId}/logs`
  );
};

export const verifyBulkContainers = (companyId, terminalId, params) => {
  params.data = JSON.stringify(params.data);
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/verify-bulk-containers?${qs}`
  );
};

export const bulkSaveAndUpdate = (companyId, terminalId, params) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/containers/bulk-save-update`,
    params
  );
};
//========================= Driver Services =====================================================================

export const getTransporterDrivers = (
  companyId,
  terminalId,
  transporterId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers?${qs}`
  );
};

export const getTransporterDriversByAttributes = (
  companyId,
  terminalId,
  transporterId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/by-attributes?${qs}`
  );
};

export const getTransporterDriver = (
  companyId,
  terminalId,
  transporterId,
  driverId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/${driverId}`
  );
};

export const addDriver = (companyId, terminalId, transporterId, driver) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers`,
    driver
  );
};

export const editDriver = (
  companyId,
  terminalId,
  transporterId,
  driverId,
  driver
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/${driverId}`,
    driver
  );
};

export const updateDriverTransporter = (
  companyId,
  terminalId,
  transporterId,
  driverId,
  driver
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/${driverId}/update-transporter`,
    driver
  );
};

export const deleteDriver = (
  companyId,
  terminalId,
  transporterId,
  driverId
) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/${driverId}`
  );
};

export const getTruckDriversByAttributes = (
  companyId,
  terminalId,
  transporterId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/truck-driver-by-attributes?${qs}`
  );
};

export const updateTruckDrivers = (companyId, terminalId, transporterId, data) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/block-transporter`,
    data
  );
};

export const updateAllTruckDrivers = (companyId, terminalId, data) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/block-all-transporter`,
    data
  );
};

export const updateDriverStatus = (companyId, terminalId, transporterId, driverId, driver) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/${driverId}/status/update`,
    driver
  );
}

export const syncDriversWithFlm = (companyId, terminalId, transporterId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/sync`
  );
};

//========================= Truck Services =====================================================================

export const getTransporterTrucks = (
  companyId,
  terminalId,
  transporterId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks?${qs}`
  );
};

export const getTransporterTrucksByAttributes = (
  companyId,
  terminalId,
  transporterId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/by-attributes?${qs}`
  );
};

export const addTruck = (companyId, terminalId, transporterId, truck) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks`,
    truck
  );
};

export const editTruck = (
  companyId,
  terminalId,
  transporterId,
  truckId,
  truck
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/${truckId}`,
    truck
  );
};

export const updateTruckTransporter = (
  companyId,
  terminalId,
  transporterId,
  truckId,
  truck
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/${truckId}/update-transporter`,
    truck
  );
};

export const deleteTruck = (companyId, terminalId, transporterId, truckId) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/${truckId}`
  );
};

export const toggleTruckStatus = (
  companyId,
  terminalId,
  transporterId,
  truckId
) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/${truckId}/toggle-status`
  );
};

export const updateTruckStatus = (companyId, terminalId, transporterId, truckId, truck) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/${truckId}/status/update`,
    truck
  );
}

export const syncTrucksWithFlm = (companyId, terminalId, transporterId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/sync`
  );
};

export const syncTransportersWithFlm = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/sync/?${qs}`
  );
};

//=========================== Notification Recipient Services =============================================================

export const getNotifications = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/notifications/?${qs}`
  );
};

export const markAsRead = (
  companyId,
  terminalId,
  recipient_notification_id,
  params = ''
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/notifications/${recipient_notification_id}`,
    params
  );
};

export const viewedNotifications = (companyId, terminalId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/notifications/viewed`
  );
};

export const getUnreadNotificationCount = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/notifications/count?${qs}`
  );
};

//=========================== Notification Recipient Services =============================================================

export const getNotificationTypes = (params = '') => {
  // let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/notification/types`);
};

// ============================ Report Email Schedule Services ===========================================================================

export const saveReportEmailSchedule = (
  companyId,
  terminalId,
  data,
  id = ''
) => {
  const type = (id && put) || post;
  return type(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/report/email-schedule/${id}`,
    data
  );
};

export const getReportEmailSchedules = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/report/email-schedule?${qs}`
  );
};

export const getReportEmailSchedule = (
  companyId,
  terminalId,
  id,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/report/email-schedule/${id}?${qs}`
  );
};

export const deleteReportEmailSchedule = (companyId, terminalId, id) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/report/email-schedule/${id}`
  );
};

// ============================ Edifact Services ===========================================================================

export const saveEDIMailSchedule = (companyId, terminalId, data, id = '') => {
  const type = (id && put) || post;
  return type(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/email-schedule/${id}`,
    data
  );
};

export const getEDIMailSchedules = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/email-schedule?${qs}`
  );
};

export const getEDIMailSchedule = (companyId, terminalId, id, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/email-schedule/${id}?${qs}`
  );
};

export const deleteEDIMailSchedule = (companyId, terminalId, id) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/email-schedule/${id}`
  );
};

export const getEDIContainersReport = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/containers-report?${qs}`
  );
};

export const getEDIEmailsReport = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/emails-report?${qs}`
  );
};

export const getContainersForGenerateEDI = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/get-containers?${qs}`
  );
};

export const getEDINames = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/edi-names?${qs}`
  );
};

export const generateEDIFromEDIId = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return getDownload(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/generate?${qs}`
  );
};

export const generateEDIFromContainerIds = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return getDownload(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/generate/container-ids?${qs}`
  );
};

export const edifactGatedInReport = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/gated-in?${qs}`
  );
};

export const edifactGatedOutReport = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/gated-out?${qs}`
  );
};

export const generateCodeco = (companyId, terminalId, data, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/codeco?${qs}`,
    data
  );
};

export const getEDIs = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/?${qs}`
  );
};

export const getEDI = (companyId, terminalId, ediId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/edi/${ediId}?${qs}`
  );
};

// ============================ ISO Codes Services ===========================================================================

export const getISOCodes = (params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/iso?${qs}`);
};

export const getISOCodeDetails = (params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/iso/details?${qs}`);
};

export const getISOCodesByAttributes = (params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/iso/by-attributes?${qs}`);
};

export const getISOCode = id => {
  return get(`${baseUrl}/iso/${id}`);
};

export const saveISOCode = (data, id = '') => {
  const type = (id && put) || post;
  return type(`${baseUrl}/iso/${id}`, data);
};

export const editISOCode = (id, iso) => {
  return put(`${baseUrl}/iso/${id}`, iso);
};

// ===================================== Cro Services ========================================================================

export const getAllCROs = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros?${qs}`
  );
};

export const getAllCROStatusReportData = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/allCROReportData?${qs}`
  );
};

export const getCROsByAttribute = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/by-attribute?${qs}`
  );
};

export const getCRO = (companyId, terminalId, croId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${croId}`
  );
};

export const getEachCRODetail = (companyId, terminalId, croId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${croId}/each-cro-report`
  );
};

export const croReportEmail = (companyId, terminalId, data) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/each-cro-report-email`,
    data
  );
};

export const getCROEquipment = (companyId, terminalId, croId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${croId}/equipment`
  );
};

export const addCRO = (companyId, terminalId, cro) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros`,
    cro
  );
};

export const editCRO = (companyId, terminalId, croId, cro) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${croId}`,
    cro
  );
};

export const updateCROTransporter = (
  companyId,
  terminalId,
  croId,
  transporterCompanyId
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${croId}/update-transporter`,
    { transporterCompanyId }
  );
};

export const blockCRO = (companyId, terminalId, croId, params) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${croId}/toggle-block`,
    params
  );
};

export const toggleCROStatus = (companyId, terminalId, croId) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/${croId}/toggle-status`
  );
};

export const getRemainingCROContainersBalance = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cros/remaining/count?${qs}`
  );
};

// ============================ Cargo Services ===========================================================================

export const getCargos = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cargos?${qs}`
  );
};

export const addCargo = (companyId, terminalId, cargos) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cargos/`,
    cargos
  );
};

export const editCargo = (companyId, terminalId, id, cargos) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cargos/${id}`,
    cargos
  );
};
export const saveCargo = (cargos, companyId, terminalId, id = '') => {
  const type = (id && put) || post;
  return type(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/cargos/${id}`,
    cargos
  );
};

// ============================ Transition Grade Services ===========================================================================

export const getTransitionGrades = (params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/transition/grades?${qs}`);
};

export const addTransitionGrade = transitionGrade => {
  return post(`${baseUrl}/transition/grades`, transitionGrade);
};

// ============================ Vat ===========================================================================

export const saveVat = (params = '') => {
  return post(`${baseUrl}/companies/vat`, params);
};

export const getTerminalsWithVat = (companyId, params) => {
  let queryString = new URLSearchParams(params).toString();
  return get(`${baseUrl}/companies/${companyId}/terminals/Vat?${queryString}`);
};
// ============================ Banks ===========================================================================

export const saveBankAccount = (companyId, terminalId, params = {}) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/bank-accounts`,
    params
  );
};

export const getBankAccounts = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/bank-accounts?${qs}`
  );
};

export const getBankAccount = (companyId, terminalId, bankId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/bank-accounts/${bankId}`
  );
};

export const editBankAccount = (companyId, terminalId, params = '', editId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/bank-accounts/${editId}`,
    params
  );
};

export const toggleBankAccountStatus = (companyId, terminalId, bankId) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/bank-accounts/${bankId}/toggle-status`
  );
};
// ============================ Transition Statuses ===========================================================================

export const getTransitionServices = (companyId, terminalId, params = '') => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/${params.containerTransitionId}/services?${qs}`
  );
};
// ===============================Invoices ======================================
export const getInvoices = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/invoices?${qs}`
  );
};

export const getInvoice = (companyId, terminalId, invoiceId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/invoices/${invoiceId}`
  );
};

export const getOutStandingInvoices = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/invoices/outstanding?${qs}`
  );
};

export const searchInvoiceNo = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/invoices/search?${qs}`
  );
};

export const addInvoice = (
  companyId,
  terminalId,
  shippingLineId,
  params = {}
) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${shippingLineId}/invoices`,
    params
  );
};

export const savePayment = (companyId, terminalId, paymentData) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/save-payment`,
    paymentData
  );
};

//=======================================Payment modes=================================

export const getPaymentModes = () => {
  return get(`${baseUrl}/payment-modes`);
};
// ===============================Container Transitions =================================================

export const getTransitionsByAttribute = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/transitions/by-attribute?${qs}`
  );
};

// ===============================Vehicles =================================================

export const getAllActiveVehiclePickupSlip = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle-pickup-slip?${qs}`
  );
};

export const getAllVehicleOuts = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/out?${qs}`
  );
};

export const getAllVehicleOutsideOuts = (
  companyId,
  terminalId,
  params = ''
) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/outside?${qs}`
  );
};

export const getVehicleGrades = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/vehicle-grades`
  );
};

export const getVehicleChassisNumbers = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/chassis-numbers?${qs}`
  );
};

export const getVehicleKeyNumbers = (companyId, terminalId, params = '') => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/key-numbers?${qs}`
  );
};

export const getVehicleIns = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/ins?${qs}`
  );
};

export const getVehicleInsForReport = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/in/report?${qs}`
  );
};

export const getVehicleIn = (companyId, terminalId, vehicleInId, params) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/${vehicleInId}?${qs}`
  );
};

export const saveVehicles = (companyId, terminalId, data) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle`,
    data
  );
};

export const getReadyVehicle = (companyId, terminalId, query = '') => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/out/ready-vehicles?query=${query}`
  );
};

export const getReadyVehicleOutside = (companyId, terminalId, query = '') => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/outside/ready-vehicles?query=${query}`
  );
};

export const checkIfChassisNoExists = (
  companyId,
  terminalId,
  chassisNo = ''
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/check-chassis-no?chassisNo=${chassisNo}`
  );
};

export const getVehiclePickupSlipIds = (companyId, terminalId, params = '') => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle-pickup-slip/get-pickupSlip-Ids?${qs}`
  );
};

export const addVehicleOut = (companyId, terminalId, vehicleOut) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/out`,
    vehicleOut
  );
};

export const addVehicleOutsideOut = (companyId, terminalId, vehicleOut) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/outside`,
    vehicleOut
  );
};

export const getVehicleOutIds = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/out/get-vehicle-out-Ids?${qs}`
  );
};

export const getVehicleOutsideOutIds = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/outside/get-vehicle-outside-Ids?${qs}`
  );
};

export const getVehicleInIds = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/get-vehicle-in-Ids?${qs}`
  );
};

export const addVehiclePickupSlip = (companyId, terminalId, pickupSlip) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle-pickup-slip`,
    pickupSlip
  );
};

export const revertVehicleOut = (companyId, terminalId, vehicleOutId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/out/revert/${vehicleOutId}/`
  );
};

export const revertVehicleOutsideOut = (
  companyId,
  terminalId,
  vehicleOutId
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/outside/revert/${vehicleOutId}/`
  );
};

export const revertVehicleIn = (companyId, terminalId, vehicleInId) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/revert/${vehicleInId}/`
  );
};

export const revertVehiclePickupSlip = (
  companyId,
  terminalId,
  pickupSlipId,
  data
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle-pickup-slip/${pickupSlipId}/revert`,
    data
  );
};

// =============================== Fasah In-Appointments =================================================

export const getInAppointments = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments?${qs}`
  );
};

export const getInAppointment = (companyId, terminalId, inAppointmentId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/${inAppointmentId}`
  );
};

export const getLapseInAppointment = (
  companyId,
  terminalId,
  containerId,
  appointmentId = ''
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/lapse/${containerId}/${appointmentId}`
  );
};

export const getInAppointmentIds = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/get-appointment-numbers?${qs}`
  );
};

export const getInAppointmentEIRInIds = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/get-eirIn-ids?${qs}`
  );
};

export const getInAppointmentContainers = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/get-containers?${qs}`
  );
};

export const saveInAppointment = (companyId, terminalId, data, id = '') => {
  const method = id !== '' ? put : post;
  return method(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/${id}`,
    data
  );
};

export const revertInAppointment = (companyId, terminalId, inAppointmentId) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/revert/${inAppointmentId}`
  );
};

export const getAppointmentWithEIRInId = (
  companyId,
  terminalId,
  transitionId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/by-eir-in/${transitionId}`
  );
};

export const toggleInAppointmentStatus = (companyId, terminalId, id, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/${id}/toggle-status`,
    data
  );
};

export const getInAppointmentEIRInIdsByContainers = (
  companyId,
  terminalId,
  params
) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/in-appointments/container-eirIn-ids?${qs}`
  );
};

// =============================== Fasah Out-Appointments =================================================

export const getOutAppointments = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments?${qs}`
  );
};

export const getOutAppointment = (companyId, terminalId, outAppointmentId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/${outAppointmentId}`
  );
};

export const getLapseOutAppointment = (
  companyId,
  terminalId,
  containerId,
  appointmentId = ''
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/lapse/${containerId}/${appointmentId}`
  );
};

export const getOutAppointmentIds = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/get-appointment-numbers?${qs}`
  );
};

export const getOutAppointmentEIROutIds = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/get-eirOut-ids?${qs}`
  );
};

export const getOutAppointmentEIROutIdsByContainers = (
  companyId,
  terminalId,
  params
) => {
  let qs = new URLSearchParams(params).toString();
  qs = decodeURIComponent(qs);
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/container-eirOut-ids?${qs}`
  );
};

export const getOutAppointmentContainers = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/get-containers?${qs}`
  );
};

export const saveOutAppointment = (companyId, terminalId, data, id = '') => {
  const method = id !== '' ? put : post;
  return method(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/${id}`,
    data
  );
};

export const revertOutAppointment = (
  companyId,
  terminalId,
  outAppointmentId
) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/revert/${outAppointmentId}`
  );
};

export const toggleOutAppointmentStatus = (companyId, terminalId, id, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/${id}/toggle-status`,
    data
  );
};

export const getContainerFasahReportData = (companyId, terminalId, params) => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/report/container?${qs}`
  );
};

export const getAppointmentWithEIROutId = (companyId, terminalId, eirOutId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/out-appointments/by-eir-out/${eirOutId}`
  );
};

export const verifyBulkAppointments = (companyId, terminalId, params = {}) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/bulk-appointments/verify`,
    params
  );
};

export const saveBulkAppointments = (companyId, terminalId, params = {}) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/bulk-appointments`,
    params
  );
};

// ================================= Fasah Payments ========================================

export const getFasahPayments = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/payments?${qs}`
  );
};

export const getFasahUnpaidPayments = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/payments/fasah-unpaid?${qs}`
  );
};

export const saveFasahPayments = (companyId, terminalId, params = {}) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/payments`,
    params
  );
};

export const updatePaymentStatus = (companyId, terminalId, id, data) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/payments/${id}`,
    data
  );
};

export const getFasahSids = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/fasah/payments/sids?${qs}`
  );
};

// =================================Bulk Settings========================================

export const getBulkServices = (companyId, terminalId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/bulk-settings/services?${qs}`
  );
};

export const saveBulkServices = (companyId, terminalId, params = {}) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/bulk-settings/services`,
    params
  );
};

// ================================= Fasah Dashboard ========================================

export const getFasahDashboardStats = (companyId, terminalId, params = '') => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/fasah-stats?${queryParams}`
  );
};

export const getFasahDashboardGraphData = (
  companyId,
  terminalId,
  params = {}
) => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/fasah-graph-data?${queryParams}`
  );
};

// ================================= Edi Dashboard ========================================

export const getEdiDashboardStats = (companyId, terminalId, params = '') => {
  const queryParams = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/edi-stats?${queryParams}`
  );
};

export const getNotSentSchedule = (companyId, terminalId) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/edi-not-sent`
  );
};

export const resentScheduledEmail = (companyId, terminalId, data = []) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/resent-mail`,
    data
  );
};

export const deleteScheduledEmail = (companyId, terminalId, scheduleId) => {
  return destroy(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/dashboard/delete-schedule/${scheduleId}`
  );
};

export const getJobTransporterDriver = (
  companyId,
  terminalId,
  transporterId,
  driverId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/driver/${driverId}`
  );
};

// ================================= OutSide terminals ========================================

export const getOutsideTerminals = () => {
  return get(`${baseUrl}/outside-terminals`);
};

export const getVehicleOutsideChassisNumbers = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/outside/chassis-numbers?${qs}`
  );
};

export const getOutsideVehicleKeyNumbers = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/vehicle/outside/key-numbers?${qs}`
  );
};

export const addTruckDriverMapping = (companyId, terminalId, transporterId, truck) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/upload-bulk-truck-driver`,
    truck
  );
};
export const addAssignDriver = (companyId, terminalId, firmId, service) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${firmId}/trucks/assignTruckDriver`,
    service
  );
};

export const removeAssignDriver = (
  companyId,
  terminalId,
  firmId,
  truckId,
  service
) => {
  return put(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${firmId}/trucks/${truckId}/deallocate-driver`,
    service
  );
};

export const getDriverDetail = (
  companyId,
  terminalId,
  transporterId,
  driverId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/drivers/${driverId}`
  );
};

export const getTruckDetail = (
  companyId,
  terminalId,
  transporterId,
  truckId
) => {
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/${truckId}`
  );
};

export const addTruckDriver = (companyId, terminalId, transporterId, truck) => {
  return post(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/firms/${transporterId}/trucks/truck-driver`,
    truck
  );
};

export const getAllTrucks = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/trucks/get-all?${qs}`
  );
};


export const getAllDrivers = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/drivers/get-all?${qs}`
  );
};

export const getTruckNumbers = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/trucks/get-truck-numbers?${qs}`
  );
};

export const getDriverNameAndIds = (
  companyId,
  terminalId,
  params = ''
) => {
  const qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/terminals/${terminalId}/drivers/get-name-ids?${qs}`
  );
};
